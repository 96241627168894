import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { clsx } from "clsx";
import * as React from "react";

const buttonVariants = cva(
    "ds-inline-flex ds-font-text ds-items-center ds-font-400 ds-justify-center ds-gap-2 ds-whitespace-nowrap ds-transition-all ds-duration-100 ds-transform ds-transition ds-delay50 ds-duration-300 active:ds-scale-[.99] ds-leading-none",
    {
        variants: {
            variant: {
                primary: "ds-bg-blue-500 hover:ds-bg-blue-600 ds-text-white-0 [&_circle]:ds-stroke-white-0",
                primary_outline: "ds-border ds-border-blue-600 hover:ds-bg-blue-100/10 ds-text-blue-700 [&_circle]:ds-stroke-blue-700",
                destructive: "ds-bg-red-600 hover:ds-bg-red-700 ds-text-white-0 [&_circle]:ds-stroke-white-0",
                secondary_outline:
                    "ds-border ds-border-1 ds-border-white-600 hover:ds-bg-white-100 ds-text-black-900 [&_circle]:ds-stroke-black-900",
                secondary: "ds-bg-black-900 hover:ds-bg-black-800 ds-text-white-50 [&_circle]:ds-stroke-white-50",
                disabled:
                    "ds-bg-white-100 ds-border-1 ds-border-white-600 ds-text-black-700 ds-cursor-not-allowed [&_circle]:ds-stroke-black-500",
                link: "ds-bg-white ds-text-black-900[&_circle]:ds-stroke-black-900 ds-border-none ",
            },
            size: {
                "24": "ds-h-[24px] ds-rounded-6 ds-border-half ds-text-12 ds-px-8",
                "28": "ds-h-[28px] ds-rounded-6 ds-border-half ds-text-13 ds-px-8",
                "32": "ds-h-[32px] ds-rounded-[8px] ds-text-13 ds-px-4",
                "36": "ds-h-[36px] ds-rounded-[8px] ds-text-14 ds-px-8",
                "40": "ds-h-[40px] ds-rounded-[8px] ds-px-12 ds-text-15",
                "44": "ds-h-[44px] ds-rounded-[8px] ds-text-[14.5px] tracking-[-.4px] ds-px-12",
            },
            uplifted: {
                true: "ds-border-1  ds-duration-100 ds-border-[rgba(0,0,0,0.0)] ds-shadow-[inset_0px_-3px_0px_0px_rgba(0,0,0,0.25)] ds-pb-1 ds-translate-y-[-1px]",
                false: "ds-border-1 ds-border-[rgba(0,0,0,0.40)]",
            },
        },
        defaultVariants: {
            variant: "primary",
            size: "36",
            uplifted: true,
        },
    },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, children, variant = "primary", size, asChild = false, uplifted = true, ...props }, ref) => {
        const Comp = (asChild ? Slot : "button") as React.ElementType;
        const isUplifted = uplifted === true;
        return (
            <>
                <Comp
                    className={clsx(
                        className,
                        buttonVariants({ variant, size, className, uplifted }),
                        isUplifted && variant === "secondary" && "ds-shadow-[#ffffff2b]",
                        isUplifted && variant === "secondary_outline" && "ds-shadow-[#7575752b]",
                        isUplifted && variant === "primary_outline" && "ds-shadow-[#076de13e]",
                        isUplifted && (size === "36" || size === "32") && "ds-shadow-[inset_0px_-2.5px_0px_0px_rgba(0,0,0,0.32)] ds-pb-2",
                        isUplifted && (size === "40" || size === "44") && "ds-shadow-[inset_0px_-3.5px_0px_0px_rgba(0,0,0,0.32)] ds-pb-2",
                        isUplifted &&
                            (size === "28" || size === "24") &&
                            "ds-shadow-[inset_0px_-1.5px_0px_0px_rgba(0,0,0,0.32)] ds-pb-1 ds-px-8",
                        variant === "link" && "ds-border-0 ds-box-shadow-none",
                        "ds-outline-none",
                    )}
                    ref={ref}
                    asChild={asChild}
                    {...props}
                >
                    {children}
                </Comp>
            </>
        );
    },
);
Button.displayName = "Button";

export { Button, buttonVariants };
