import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";
import { clsx } from "clsx";

const inputVariants = cva(
    "ds-inline-flex ds-caret-blue-600 ds-font-text ds-items-center ds-justify-center ds-gap-2 ds-whitespace-nowrap ds-transition-all ds-duration-100 ds-outline-none focus:ds-border-blue-600 focus:ds-border-[1px] focus:ds-ring-[3px] focus:ds-ring-blue-600/10 focus:ds-outline-none",
    {
        variants: {
            state: {
                normal: "ds-bg-white-0 ds-border-1 ds-border-white-600 ds-text-black-900 ds-placeholder:text-black-700",
                disabled:
                    "ds-bg-white-100 ds-border-1 ds-border-white-600 ds-text-black-900 ds-cursor-not-allowed [&_circle]:ds-stroke-black-500 ",
                error: "ds-border-1 !ds-border-red-600  focus:ds-ring-red-600/10",
            },
            size: {
                "24": "ds-h-[24px] ds-rounded-6 ds-border-half ds-text-12 ds-px-8 !focus:ds-ring-[1px]",
                "28": "ds-h-[28px] ds-rounded-6 ds-border-half ds-text-13 ds-px-8 !focus:ds-ring-[2px]",
                "32": "ds-h-[32px] ds-rounded-[8px] ds-text-13 ds-px-8 ",
                "36": "ds-h-[36px] ds-rounded-[8px]  ds-text-14 ds-px-12 ",
                "40": "ds-h-[40px] ds-rounded-[8px] ds-px-16 ds-text-15",
                "44": "ds-h-[44px] ds-rounded-[8px] ds-text-15 ds-px-12",
            },
        },
        defaultVariants: {
            state: "normal",
            size: "36",
        },
    },
);

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">, VariantProps<typeof inputVariants> {
    asChild?: boolean;
    size?: "24" | "28" | "32" | "36" | "40" | "44";
    state?: "normal" | "disabled" | "error";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, state = "normal", size = "36", ...props }, ref) => {
    return (
        <input
            type={type}
            className={clsx(inputVariants({ state, size }), className)}
            disabled={state === "disabled"}
            ref={ref}
            {...props}
        />
    );
});
Input.displayName = "Input";

export { Input, type InputProps };
